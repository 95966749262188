<template>
    <div v-if="course_lesson.length && native_data" class="p-1">
        <p class="mt-1">Course</p>
        <multi-select :multiple="false" :id="'course_select' + Math.random()"
            :options="course_options"
            :allow_empty="false"
            v-model="native_data.course"
            @input="getLessonList"
        />
        <p class="mt-1">Lesson</p>
        <multi-select :multiple="false" :id="'lesson_select' + Math.random()"
            :options="lesson_options"
            :allow_empty="false"
            v-model="native_data.lesson"
        />
        <p class="mt-1">Note Lesson</p>
        <b-input v-model="native_data.note"></b-input>
    </div>
</template>
<script>
export default {
    props: {
        course_lesson: {type: Array, required: true},
        native_data: {type: Object, required: true}
    },
    data(){
        return {
            lesson_options: [],
        }
    },
    computed: {
        course_options(){
            return this.course_lesson.map((course) => {return {text: course.title, value: course.title }})
        }
    },
    watch: {
        'native_data.course_id': {
            handler: function (new_val, old_val){
                if (new_val && new_val !== old_val){
                    let course = this.course_lesson.find((item)=> {
                        return new_val === item.title
                    })
                    this.lesson_options = course.lessons.map((lesson) => {return {text: lesson.title, value: lesson.title }});
                }
            },
            immediate: true
        }
    },
    created(){
        this.getLessonList(this.native_data.course)
    },
    methods: {
        getLessonList(value){
            let course = this.course_lesson.find((item)=> {
                return value === item.title
            })
            this.lesson_options = course.lessons.map((lesson) => {return {text: lesson.title, value: lesson.title }});
            if (value !== this.native_data.course_id){
                this.native_data.lesson_id = null;
            }
        },
    }
}
</script>
<template>
    <div>
        <div>
            <p>Day</p>
            <day-selection @selected_day="selectedDay"></day-selection>
        </div>
        <div class="border mt-1">
            <p class="mt-1">Native Lesson</p>
            <select-component v-if="course_lesson.length" :course_lesson="course_lesson" :native_data="native_data[selected_day_index].native_lesson" key="'native_lesson'"></select-component>
        </div>
        <div class="d-flex mt-1">
            <p>Prepare before class (in app)</p>
            <b-button class="ml-1" size="sm" variant="primary" @click="addPrepareBeforeClass">+ Add</b-button>
        </div>
        <div v-for="(item, index) in native_data[selected_day_index].prepare_before_class" :key="'prepare_before_class_'+index" class="border">
            <form-generator :model="item" :schema="prepare_schema"></form-generator>
        </div>
        <p class="mt-1">Objective</p>
        <ui-component class="border mb-1"
            :component-data="native_data[selected_day_index].objective"
            :key="`objective_${selected_day_index}`"
        />
    </div>
</template>
<script>
import DaySelection from './DaySelection.vue'
import SelectComponent from './SelectComponent.vue'
import translableRichTextDefault from '@core/layouts/components/input-fields/translatable-richtext-field/defaultObject'
const prepare_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Course', field: 'course'},
      { label: 'Lesson', field: 'lesson'},
      { label: 'Note', field: 'note'},
    ]
  }
];
export default {
    components: {
        DaySelection,
        SelectComponent
    },
    props: {
        course_lesson: {type: Array, default: ()=> {return []}},
        native_data: {type: Array, required: true}
    },
    data(){
        return {
            selected_day_index: 0,
            learning_path_default: {
                native_lesson: {
                    course: null,
                    lesson: null,
                    note: null,
                },
                prepare_before_class: [],
                objective: JSON.parse(JSON.stringify(translableRichTextDefault))
            },
            prepare_schema
        }
    },
    created(){
        this.selectedDay();
    },
    methods: {
        selectedDay(selected_day = 1){
            this.selected_day_index = this.native_data.findIndex((item)=>{return item.day === selected_day});
            if (this.selected_day_index === -1){
                let default_obj = JSON.parse(JSON.stringify(this.learning_path_default));
                this.native_data.push({...default_obj, day: selected_day});
                this.selected_day_index = this.native_data.length -1;
            }
        },
        addPrepareBeforeClass(){
            this.native_data[this.selected_day_index].prepare_before_class.push({
                course: null,
                lesson: null,
                note: null,
            })
        }
    }
}
</script>
<template>
    <div class="page-content" v-if="item">
        <page-breadcrumb title="Speaking Test Detail" class="mb-2" :items="breadcrumbItems" />
        <b-card class="mt-1">
            <form-generator :model="item" :schema="item_form_schema">
                <template #slot_suggested_learning_path>
                    <span>Title</span>
                    <ui-component class="border"
                        :component-data="item.suggested_learning_path.title"
                        :key="'suggested_learning_path_title'"
                    />
                    <!-- Description -->
                    <div class="d-flex mt-1 mb-2">
                        <span>Description</span>
                        <b-button class="ml-1" size="sm" variant="primary" @click="addDescrition">+ Add</b-button>
                    </div>
                    <div v-for="(des, index) in item.suggested_learning_path.description" :key="des._id || index" class="border p-1">
                        <span>Title</span>
                        <ui-component class="border mb-1"
                            :component-data="des.title"
                            :key="'des_title_'+index"
                        />
                        <span>Subtitle</span>
                        <ui-component class="border mb-1"
                            :component-data="des.subtitle"
                            :key="'des_subtitle_'+index"
                        />
                    </div>
                    <!-- Course Objectives -->
                    <div>
                        <p>Course Objectives</p>
                        <div class="p-1">
                            <p>Subtitle</p>
                            <ui-component class="border mb-1"
                                :component-data="item.suggested_learning_path.course_objectives.subtitle"
                                :key="'course_objectives_subtitle'"
                            />
                            <p>Content</p>
                            <ui-component class="border mb-1"
                                :component-data="item.suggested_learning_path.course_objectives.content"
                                :key="'course_objectives_content'"
                            />
                        </div>
                    </div>
                    <div>
                        <p>Methodology</p>
                        <div class="p-1">
                            <p class="mt-1">Subtitle</p>
                            <ui-component class="border mb-1"
                                :component-data="item.suggested_learning_path.methodology.subtitle"
                                :key="'methodology_subtitle'"
                            />
                            <p class="mt-1">Image</p>
                            <ui-component class="border mb-1"
                                :component-data="item.suggested_learning_path.methodology.image"
                                :key="'methodology_input'"
                            />
                            <p class="mt-1">Content</p>
                            <div class="p-1">
                                <p>Input</p>
                                <ui-component class="border mb-1"
                                    :component-data="item.suggested_learning_path.methodology.input"
                                    :key="'methodology_input'"
                                />
                                <p>Intake</p>
                                <ui-component class="border mb-1"
                                    :component-data="item.suggested_learning_path.methodology.intake"
                                    :key="'methodology_intake'"
                                />
                                <p>Output</p>
                                <ui-component class="border mb-1"
                                    :component-data="item.suggested_learning_path.methodology.output"
                                    :key="'methodology_output'"
                                />
                                <p>Conclusion</p>
                                <ui-component class="border mb-1"
                                    :component-data="item.suggested_learning_path.methodology.conclusion"
                                    :key="'methodology_conclusion'"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <template #slot_learning_path>
                    <div class="p-1">
                        <p>Description</p>
                        <div class="p-1">
                            <p>Title</p>
                            <ui-component class="border mb-1"
                                :component-data="item.learning_path.description.title"
                                :key="'learning_path_description_title'"
                            />
                            <p>Subtitle</p>
                            <ui-component class="border mb-1"
                                :component-data="item.learning_path.description.subtitle"
                                :key="'learning_path_description_subtitle'"
                            />
                            <p>Content</p>
                            <div class="p-1">
                                <p>Milestone</p>
                                <ui-component class="border mb-1"
                                    :component-data="item.learning_path.description.milestone"
                                    :key="'learning_path_description_milestone'"
                                />
                                <p>Note</p>
                                <ui-component class="border mb-1"
                                    :component-data="item.learning_path.description.note"
                                    :key="'learning_path_description_note'"
                                />
                            </div>
                        </div>
                        <p>Detail</p>
                        <div class="p-1">
                            <native-lesson :course_lesson="course_lesson" :native_data="item.learning_path.detail"></native-lesson>
                            <p>Extra Url</p>
                            <b-form-input class="mt-1" v-model="item.learning_path.extra_url" />
                            <p>Extra Url Text</p>
                            <ui-component class="border mb-1"
                                :component-data="item.learning_path.extra_url_text"
                                :key="'learning_path_extra_url_text'"
                            />
                        </div>
                    </div>
                </template>
            </form-generator>
        </b-card>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>
</template>
<script>
const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Level Text', field: 'level_text', input_type: 'ui-component', validate: { required: true }},
      { label: 'What you should do next title', field: 'next_step_title', input_type: 'ui-component'},
      { label: 'What you should do next content', field: 'next_step_content', input_type: 'ui-component'},
      { label: 'Suggested learning path', field: 'suggested_learning_path', input_type: 'slot'},
      { label: 'Learning path', field: 'learning_path', input_type: 'slot'},
    ]
  }
];
import service from '../service'
import translatableRichtextDefault from '@core/layouts/components/input-fields/translatable-richtext-field/defaultObject.js'

import NativeLesson from './components/NativeLesson'
export default {
    components: {
        NativeLesson
    },
    data() {
        return {
            item_form_schema,
            item: null,
            course_lesson: [],
            isUpdating: false,
            isDeleting: false,
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Speaking Test Levels',
                    to: { name: 'native11-speaking-test-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created() {
        this.getSpeakingTestLevel();
        this.getCourseLesson();
    },
    methods: {
        async getSpeakingTestLevel(){
            this.item = await service.get({id: this.itemId});
        },
        async getCourseLesson(){
            let response = await service.getCourseLesson();
            if (response){
                this.course_lesson = response.courses
            }
        },
        addDescrition(){
            this.item.suggested_learning_path.description.push({
                title: JSON.parse(JSON.stringify(translatableRichtextDefault)),
                subtitle: JSON.parse(JSON.stringify(translatableRichtextDefault))
            })
        },
        async update() {
            this.isUpdating = true;
            await service.update({
                data: JSON.stringify(this.item),
            });
            this.getSpeakingTestLevel();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "native11-speaking-test-list" });
        },
    }
}
</script>
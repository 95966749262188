import api from '@/libs/axios'


export default {
  async create(payload) {
    const response = await api.post('/admin/native11-speaking-test-level', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    let response = await api.put('/admin/native11-speaking-test-level', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/native11-speaking-test-level', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/native11-speaking-test-level', {
      params,
    })
    return response.data.data
  },
  async get(params) {
    let response = await api.get('/admin/native11-speaking-test-level/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async getCourseLesson(){
    let response = await api.get('/admin/courses/all-courses', {
      })
      return response.data.data || null
  }
}

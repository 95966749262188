<template>
    <div v-if="days">
        <multi-select :multiple="false" :id="'day_select' + Math.random()"
            :options="days"
            :allow_empty="false"
            v-model="selected_day"
        />
    </div>
</template>
<script>
export default {
    data(){
        return {
            selected_day: 1,
            days: [],
        }
    },
    watch: {
        selected_day(){
            this.$emit('selected_day', this.selected_day)
        }
    },
    created(){
        this.dayList();  
    },
    methods: {
        dayList() {
            for (let i = 1; i <= 30; i++) {
                this.days.push({
                    text: i,
                    value: i
                })
            }
        },
    }
}
</script>